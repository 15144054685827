import React from 'react'
import { useState, useEffect } from 'react';

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    
    if(nodeMode==="development"){
    var baseUrl = beckendLocalApiUrl;
    }else{
     baseUrl = beckendLiveApiUrl
    }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  }
  document.title=(WebSitesettings)?WebSitesettings.WebTitle:'Skill based game';
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  },[])

  return (
    <div>
         <div className='rightContainer'>
                <div className="rcBanner flex-center ">
                    <picture className="rcBanner-img-container animate__bounce infinite ">
                        <img src="/Images/LandingPage_img/7uHeader_profile.png" alt="nothing"/>
                    </picture>
                    <div className="rcBanner-text " style={{fontWeight:'bolder'}}>{(WebSitesettings)?WebSitesettings.WebsiteName:''} <span className="rcBanner-text-bold" style={{fontWeight:'normal'}}>Win Real Cash</span></div>
                    <div className="rcBanner-footer">For best experience, open&nbsp;<a href="/">{(WebSitesettings)?WebSitesettings.WebsiteName:''}</a>&nbsp;on&nbsp;<img src={process.env.PUBLIC_URL + '/Images/chrome.png'}
                        alt="" />&nbsp;chrome mobile
                        <br />
                        Download App <a className='buttoncolor'href="https://play.google.com/store/apps/details?id=com.ludo.king&hl=en_IN&pli=1">Click Here</a>
                        <br />
                        <a className='buttoncolor'href="/about">About Us</a> ||<a className='buttoncolor'href="/contact-us">Contact Us</a> ||<a className='buttoncolor'href="/PrivacyPolicy">Privacy Policy</a> || <a className='buttoncolor'href="/term-condition">Terms & Condition</a> <br /> 
                        <a className='buttoncolor'href="/refund-policy">Refund Policy</a> || <a className='buttoncolor'href="/Gamerules">Game Rules</a> || <a className='buttoncolor'href="/responsible-gaming">Gaming Responsible</a>
                        </div>
                </div>
            </div>
    </div>
  )
}

export default Rightcontainer